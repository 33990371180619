import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  lectures: [],
  upcomingLectures: localStorage.getItem('upcomingLectures'),
  currentlyLiveLectures: localStorage.getItem('currentlyLiveLectures'),
  lectureCredits: [],
  selectedLecture: '',
  lectureRegistrantsCount: '',
  zoomRegistrantToken: '',
  relatedLectures: [],
  zoomSdkSignature: '',
  selectedLectureMaterialFiles: [],
  lecturesForSelectedSpecialty: [],
  isLecturePlaying: localStorage.getItem('isLecturePlaying'),
};

const getters = {
  lectures: (state) => {
    return state.lectures;
  },
  relatedLectures: (state) => {
    return state.relatedLectures;
  },
  upcomingLectures: (state) => {
    if (state.upcomingLectures) {
      try {
        return JSON.parse(state.upcomingLectures);
      } catch (e) {
        return state.upcomingLectures;
      }
    } else return [];
  },
  currentlyLiveLectures: (state) => {
    if (state.currentlyLiveLectures) {
      try {
        return JSON.parse(state.currentlyLiveLectures);
      } catch (e) {
        return state.currentlyLiveLectures;
      }
    } else return [];
  },
  lectureCredits: (state) => {
    return state.lectureCredits;
  },
  selectedLecture: (state) => {
    return state.selectedLecture;
  },
  lectureRegistrantsCount: (state) => {
    return state.lectureRegistrantsCount;
  },
  zoomRegistrantToken: (state) => {
    return state.zoomRegistrantToken;
  },
  zoomSdkSignature: (state) => {
    return state.zoomSdkSignature;
  },
  selectedLectureMaterialFiles: (state) => {
    return state.selectedLectureMaterialFiles;
  },
  lecturesForSelectedSpecialty: (state) => {
    return state.lecturesForSelectedSpecialty;
  },
  isLecturePlaying: (state) => {
    return state.isLecturePlaying ? JSON.parse(state.isLecturePlaying) : false;
  },
};

const actions = {
  getAllLectures(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllLecturesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let lectures = response.data.data;
          context.commit('setAllLectures', lectures);
          let currentlyLiveLectures = [];
          let upcomingLectures = [];
          let now = moment.utc();
          lectures.forEach((lecture, index) => {
            let lectureDateTime = moment.utc(lecture.lecture_datetime);
            let lectureEndTime = moment.utc(lecture.lecture_datetime).add(60, 'minutes');
            if (now.isBefore(lectureEndTime)) {
              upcomingLectures.push(lecture);
              if (now.isBetween(lectureDateTime, lectureEndTime)) {
                currentlyLiveLectures.push(lecture);
              }
            }
          });
          context.commit('setUpcomingLectures', upcomingLectures);
          context.commit('setCurrentlyLiveLectures', currentlyLiveLectures);
          resolve('got lectures');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRelatedLectures(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getRelatedLecturesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setRelatedLectures', response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUpcomingLectures(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUpcomingLecturesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let lectures = response.data.data;
          let currentlyLiveLectures = [];
          let now = moment.utc();
          context.commit('setUpcomingLectures', lectures);
          lectures.forEach((lecture, index) => {
            let lectureDateTime = moment.utc(lecture.lecture_datetime);
            let lectureEndTime = moment.utc(lecture.lecture_datetime).add(60, 'minutes');
            if (now.isBetween(lectureDateTime, lectureEndTime)) {
              currentlyLiveLectures.push(lecture);
            }
          });
          context.commit('setCurrentlyLiveLectures', currentlyLiveLectures);
          resolve('got upcoming lectures');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCurrentlyLiveLectures(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCurrentlyLiveLectures(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setUpcomingLectures', response.data.data);
          resolve('got upcoming lectures');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLectureRegistrantsCount(context, zoom_url) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getLectureRegistrantsCountUrl(zoom_url), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setLectureRegistrantsCount', response.data);
          resolve('got lecture registrants count');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getZoomRegistrantToken(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getZoomRegistrantTokenUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          let registrantToken = new URL(response.data).searchParams.get('tk');
          context.commit('setZoomRegistrantToken', registrantToken);
          resolve('got zoomRegistrantToken');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getZoomSdkSignature(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getZoomSdkSignatureUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setZoomSdkSignature', response.data);
          resolve('got zoomSdkSignature');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLectureById(context, lecture_id) {
    return new Promise((resolve, reject) => {
      context.commit('setSelectedLecture', { id: lecture_id });
      if (context.state.selectedLecture.id == lecture_id) {
        resolve('got selected lecture');
      }
      reject(context.state.selectedLecture);
    });
  },

  toggleLectureBookmark(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.toggleLectureBookmarkUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLectureMaterialFilesByLectureId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getLectureMaterialFilesByLectureIdUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setLectureMaterialFiles', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadLectureMaterialFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadLectureMaterialFileUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = response.headers['content-disposition'].split('attachment; filename=')[1];
          // The filename has quotations around it and for some reason the replacing once only
          // gets rid of the first quotation, so have to do it twice here.
          fileName = fileName.replace('"', '');
          fileName = fileName.replace('"', '');
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  submitInterestedInSpeaking(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.submitedInterestedInSpeakingUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  requestCmeTopic(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.requestCmeTopicUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerUserForZoomLecture(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.registerUserForZoomLectureUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLecturesBySpecialtyId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getLecturesBySpecialtyIdUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setLecturesForSelectedSpecialty', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setAllLectures(state, data) {
    state.lectures = data;
  },
  setRelatedLectures(state, data) {
    state.relatedLectures = data;
  },
  setUpcomingLectures(state, data) {
    data.forEach((lecture) => {
      lecture.start = moment.utc(lecture.lecture_datetime).toDate(); //Fullcalendar
    });
    localStorage.setItem('upcomingLectures', JSON.stringify(data));
    state.upcomingLectures = data;
  },
  setCurrentlyLiveLectures(state, data) {
    data.forEach((lecture) => {
      lecture.start = moment.utc(lecture.lecture_datetime).toDate(); //Fullcalendar
    });
    localStorage.setItem('currentlyLiveLectures', JSON.stringify(data));
    state.currentlyLiveLectures = data;
  },
  setLectureCredits(state, data) {
    state.lectureCredits = data;
  },
  setLectureRegistrantsCount(state, data) {
    state.lectureRegistrantsCount = data;
  },
  setZoomRegistrantToken(state, data) {
    state.zoomRegistrantToken = data;
  },
  setZoomSdkSignature(state, data) {
    state.zoomSdkSignature = data;
  },
  setSelectedLecture(state, data) {
    for (var i = 0; i < state.lectures.length; i++) {
      if (state.lectures[i].id == data.id) {
        state.selectedLecture = state.lectures[i];
      }
    }
  },
  setLectureMaterialFiles(state, data) {
    state.selectedLectureMaterialFiles = data;
  },
  setLecturesForSelectedSpecialty(state, data) {
    state.lecturesForSelectedSpecialty = data;
  },
  setIsLecturePlaying(state, data) {
    localStorage.setItem('isLecturePlaying', JSON.stringify(data));
    state.isLecturePlaying = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
